import { IActorProfile } from '@app/+actor/models/actor-profile.model';

export interface IRole {
  id?: string;

  name: string;
  characterName: string;
  roleDesc: string;
  vocalRange: string;
  rate: string;
  keywords: string[];

  assignedActor?: IActorProfile;

  createStamp: string;
  updateStamp: string;
  lastModifiedBy: string;
  isActive: boolean;
}
export const IROLE_DEFAULTS : IRole = {
  id: null,
  name: null,
  characterName: null,
  roleDesc: null,
  vocalRange: null,
  rate: null,
  keywords: [],

  createStamp: null,
  updateStamp: null,
  lastModifiedBy: null,

  isActive: true
};
